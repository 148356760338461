<template>
  <form-view
    :url="`/car/filling/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
  >
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="自编号" prop="carId">
          <el-select filterable v-model="dataForm.carId" @change="handleChangeCar">
            <el-option v-for="s in carList" :key="s.id" :label="s.number" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车牌号码" prop="licensePlate">
          <el-input disabled v-model="dataForm.licensePlate"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="车辆型号" prop="carSize">
          <el-input disabled v-model="dataForm.carSize"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="加油数量" prop="fillingNumber">
          <el-input type="number" @change="calculate" v-model="dataForm.fillingNumber"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="加油类型" prop="fillingType">
          <el-select filterable v-model="dataForm.fillingType">
            <el-option v-for="s in fillingTypeList" :key="s.id" :label="s.label" :value="s.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="margin-bottom:20px;">
      <image-uploader
        @add="addImage"
        @remove="removeImage"
        :images="dataForm.images || []"
        no-title
      ></image-uploader>
    </el-row>
    <div class="section-title-row" v-if="id">
      <div class="title">操作历史</div>
      <div class="line"></div>
    </div>
    <div class="record-area" v-if="id">
      <div class="record-row" v-for="(r, i) in records" :key="i">{{r}}</div>
    </div>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
// import dayjs from 'dayjs'
import { operationTypes } from '@/utils/constants'

export default {
  name: 'car-oil-update',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      carList: [],
      fillingTypeList: [
        {
          'id': 1,
          'label': '固定加油'
        },
        {
          'id': 2,
          'label': '送油'
        }
      ],
      records: [],
      id: null
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/car/filling/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          if (this.dataForm.image) {
            this.dataForm.images = this.dataForm.image.split(',').map(j => ({ url: '/files' + j }))
            this.dataForm.imageList = this.dataForm.images.map(j => j.url.substring('/files'.length))
          } else {
            this.dataForm.images = []
            this.dataForm.imageList = []
          }
          const res = await this.$http({
            url: this.$http.adornUrl('/car/filling/operationRecord'),
            method: 'post',
            data: id
          })
          this.records = res.datas.map(d => {
            if (d.status === 3) {
              return `${d.createTime} ${d.nickName}进行了审批驳回操作，备注内容：${d.remarks}。`
            }
            return `${d.createTime} ${d.nickName}进行了${operationTypes[d.typeCode]}操作。`
          })
          this.loading = false
        }
      } else {
        this.dataForm = {
          carId: '',
          licensePlate: '',
          carSize: '',
          fillingNumber: '',
          unitPrice: '',
          totalPrice: '',
          imageList: []
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/car/list'),
        method: 'post',
        data: { carType: 1 }
      })
      this.carList = data.datas
    },

    handleChangeCar (carID) {
      console.log(carID)
      const target = this.carList.find(c => c.id === carID)
      console.log(target)
      this.dataForm.licensePlate = target.licensePlate
      this.dataForm.carSize = target.carSize
    },

    calculate () {
      this.dataForm.totalPrice = this.dataForm.fillingNumber * this.dataForm.unitPrice
    },

    addImage (path) {
      this.dataForm.imageList.push(path)
    },

    removeImage (url) {
      this.dataForm.imageList.splice(this.dataForm.imageList.indexOf(url), 1)
    },

    customValidator () {
      return true
    },

    beforeSubmit (params) {
      params.image = params.imageList.join(',')
      return params
    }
  }
}
</script>
